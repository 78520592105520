import React from "react";
import {graphql, Link} from "gatsby";
import Img from 'gatsby-image'
import injectSheet from 'react-jss';
import 'dayjs/locale/de';
import dayjs from "dayjs";

import Layout from '../containers/layout';
import SubPage from '../containers/subpage';

import ReactHtmlParser from 'react-html-parser'
import FetchedProgramContest from "../containers/FetchedProgrammContest";
import LocationBox from "../components/box/LocationBox";
import MyMap from "../components/MyMap";
import GalleryButton from "../components/GalleryButton";
import ProgramMenu from "../components/ProgramMenu";

const columnGap = 20;

const styles = theme => ({
    ticket: {
        display: 'inline-flex',
        position: 'sticky',
        top: '1.5rem',
        zIndex: 1500,
        marginTop: '2.5rem',

        fontSize: '.5rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '2.5rem',
            fontSize: '.9rem',
        },
        [theme.breakpoints.down('xs')]: {
            position: 'fixed',
            top: '1rem',
            marginTop: '0',
        },
    },
    headerWrap: {
        display: 'relative',
    },
    headerWrapper:{
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
        }
    },
    header:{
        flex: '1 1 auto',
        position: 'relative',
    },
    anchor: {
        position: 'relative',
        top: '-4.125rem',
        visibility: 'hidden',
    },
    image: {
        '& span':{
            display: 'block',
        },
    },
    [theme.breakpoints.up('md')]: {
        content: {
            position: 'relative',
            transition: 'left 250ms ease',
        },
        contentWrapper: {
            columnFill: 'auto',
            columnWidth: 300,
            columnGap: columnGap,
            maxHeight: 'calc(100vh - 13em)',
            overflow: 'hidden',
        },
    },
});

const createFluidImage = (localFile) => {
    let gatsbyImg;

    if (localFile) {
        let fluid = localFile.childImageSharp.fluid;
        gatsbyImg = (
            <Img
                fluid={fluid}
            />
        );
    }
    return gatsbyImg;
};


const ProgramTemplate = ({data, classes}) => {

    let slammers = [];
    let slamerName = '';

    let transform = (props, index) => {
        const {type, name, attribs, children} = props;
        if (name === 'div') {
            if (attribs.class.indexOf('flex') !== -1) {
                children.forEach(({type, name, attribs, children}) => {
                    if (name === 'div') {
                        if(attribs.class.indexOf('tn') !== -1){
                            slamerName = children[0].data;
                        } else if(attribs.class.indexOf('slm') !== -1){
                            slammers.push({
                                name: slamerName,
                                veranstaltung: children[0].data,
                            });
                            slamerName = '';
                        }
                    }
                });
                //remove contest from content
                return (<></>);
            }
        }
    };
    let content = new ReactHtmlParser(data.event.content, {transform});

    let gatsbyImg = data.event.featured_media? createFluidImage(data.event.featured_media.localFile): null;

    let date = dayjs(data.event.custom_fields.wpcf_veranstaltungsdatum*1000).locale('de');

    let location = data.event.location;


    if(location){
        let gatsbyImg = location.featured_media? createFluidImage(location.featured_media.localFile): null;
        let map = (<MyMap location={location.title}/>);
        location = (
            <LocationBox
                map={map}
                image={gatsbyImg}
                title={location.title}
                date={date}
                time={data.event.custom_fields.wpcf_tueroeffnung}
                {...location.custom_fields}
            />
        );
    }

    let moderation = null;
    if( data.event.custom_fields.wpcf_moderation &&
        data.event.custom_fields.wpcf_moderation.length>0 &&
        data.event.custom_fields.wpcf_moderation[0]!==''    ){
        moderation = (
            <>
            <br/>
            Moderation: {
                data.event.custom_fields.wpcf_moderation.reduce(
                    (accumulator, currentValue) => accumulator + ', ' + currentValue
                )
            }</>
        );
    }

    let opfer = null;
    if(data.event.custom_fields.wpcf_opfer && data.event.custom_fields.wpcf_opfer.length>0){
        opfer = (
            <>
            <br/>Opferlamm: {data.event.custom_fields.wpcf_opfer[0]}
            </>
        );
    }

    let preis = null;
    if(data.event.custom_fields.wpcf_preis && data.event.custom_fields.wpcf_preis.length>0){
        preis = (
            <>
            <br/>Preis: {data.event.custom_fields.wpcf_preis[0]}
            </>
        );
    }
    let button = null;
    if(data.allGallery && data.allGallery.edges && data.allGallery.edges.length>0) {
        button = (
            <div className={classes.ticket}>
                <GalleryButton to={data.allGallery.edges[0].node.slug}/>
            </div>
        );
    }

    return (
        <Layout pageTitle={"Event"}>
            <SubPage
                image={gatsbyImg}
                back={'/programm'}
            >
                <div className={'container'} style={{position:'relative'}}>
                    {button}
                    <div className={classes.headerWrapper}>
                        <div className={classes.header}>
                            <h2>{data.event.title} - {data.event.custom_fields.wpcf_untertitel}</h2>
                            <h4>
                                {date.format('D. MMMM, YYYY')}<br/>
                                Türöffnung: {data.event.custom_fields.wpcf_tueroeffnung} Uhr / Beginn: {data.event.custom_fields.wpcf_beginn} Uhr
                                {moderation}
                                {opfer}
                                {preis}
                            </h4>
                        </div>
                        <span className={classes.anchor} id="navigation">nav</span>
                        <ProgramMenu
                            events={data.events}
                            currentDay={data.event.custom_fields.wpcf_veranstaltungsdatum[0]}
                            currentTime={data.event.custom_fields.wpcf_tueroeffnung[0]}
                            currentEvent={data.event.id}
                        />
                    </div>
                    <FetchedProgramContest competition={data.event.title} />
                    {content}
                    {location}
                </div>
            </SubPage>
        </Layout>
    );
};

export default injectSheet(styles)(ProgramTemplate);

export const query = graphql`
    query programTemplateQuery($wp_id: Int!) {
        event: wordpressWpEvents(
            wordpress_id: {eq: $wp_id}
        ) {
            id
            slug
            iframe_slug
            title
            content
            location {
                title
                featured_media{
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 600) {
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                }
                custom_fields{
                    address: wpcf_adresse
                    website: wpcf_website
                    facebook: wpcf_facebook
                    instagram: wpcf_instagram
                }                
            }
            custom_fields{
                wpcf_veranstaltungsdatum
                wpcf_untertitel
                wpcf_beginn
                wpcf_tueroeffnung
                wpcf_endzeit
                wpcf_link
                wpcf_moderation
                wpcf_opfer
                wpcf_preis 
            }
            featured_media {
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 2048) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
            }
        }
        events: allWordpressWpEvents(
            filter: {content: {ne: ""}},
            sort: {fields: [custom_fields___wpcf_tueroeffnung]}
        ){
            group(field: custom_fields___wpcf_veranstaltungsdatum){
                field
                fieldValue
                totalCount
                edges{
                    event: node{
                        id
                        slug
                        title
                        location{
                            title
                        }
                        custom_fields{
                            wpcf_tueroeffnung
                        }
                    }
                }
            }
        }
        allGallery(filter: {event: {wordpress_id: {eq: $wp_id}}}) {
            edges{
                node{
                    slug
                }
            }
        }
    }
`;
import React from "react";
import injectSheet from 'react-jss';
import 'dayjs/locale/de';

import Link from 'gatsby-link';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import {faImages} from "@fortawesome/free-solid-svg-icons/faImages";

const styles = theme => {
    let background = theme.palette.background;
    //let color = theme.palette.primary.dark;
    let color = theme.palette.backgroundSecondary;
    return {
        root: {
            '&.button': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'stretch',
                justifyAlign: 'center',

                borderWidth: 2,
                borderRadius: 4,
                background: background,
                padding: 0,
                fontWeight: 600,
                textAlign: 'center',

                color: color,
                borderColor: color,

                '&:hover': {
                    background: color,
                    color: background,
                },
                [theme.breakpoints.down('xs')]: {
                    borderColor: theme.palette.secondary.dark,
                    color: theme.palette.secondary.dark,
                    fontSize: '1rem',
                    '&:hover': {
                        background: theme.palette.secondary.dark,
                        color: background,
                        color: theme.palette.secondary.light,
                    },
                },
            }
        },
        icon: {
            fontSize: '5em',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            padding: '.5em',
            [theme.breakpoints.down('xs')]: {
                fontSize: '1em',
            },

            '&::before': {
                content: '\'\'',
                position: 'absolute',
                top: 0, left: 0, bottom: 0, right: 0,
            },
        },
    }
};

const GalleryButton = ({to, href, classes}) => {
    return (
        <Link to={'/' + to} className={classes.root + ' button'}>
            <span className={classes.icon}><FontAwesomeIcon icon={faImages}/></span>
        </Link>
    );
};

export default injectSheet(styles)(GalleryButton);
import React from 'react'
import injectSheet from 'react-jss';

import marker from '../assets/marker/Venue-Markers_AmbossRampe.png';
import markerHallenstadion from '../assets/marker/Venue-Markers_Hallenstadion.png';
import markerHotel25 from '../assets/marker/Venue-Markers_Hotel25h.png';
import markerKasheme from '../assets/marker/Venue-Markers_Kasheme.png';
import markerHotel25West from '../assets/marker/Venue-Markers_Hotel25h_West.png';
import markerKosmos from '../assets/marker/Venue-Markers_Kosmos.png';
import markerPlaza from '../assets/marker/Venue-Markers_Plaza.png';
import markerSchauspiel from '../assets/marker/Venue-Markers_Schauspielhaus.png';
import markerVolkshaus from '../assets/marker/Venue-Markers_Volkshaus.png';
import markerXTra from '../assets/marker/Venue-Markers_X-tra.png';

import 'leaflet/dist/leaflet.css'

let locations = [{
    name: 'Schauspielhaus',
    popup: '<adress>Schauspielhaus<br/>Pfauen<br/>Rämistrasse 34<br/>8001 Zürich</adress>',
    position: [47.37016,8.54918],
    icon: markerSchauspiel,
},{
    name: 'X-TRA',
    popup: '<adress>X-TRA<br/>Limmatstrasse 118<br/>8005 Zürich</adress>',
    position: [47.38403, 8.53281],
    icon: markerXTra,
},{
    name: 'Kosmos',
    popup: '<adress>Kosmos<br/>Europaallee<br/>Lagerstrasse 104<br/>8004 Zürich</adress>',
    position: [47.37997, 8.52926],
    icon: markerKosmos,
},{
    name: 'Amboss',
    popup: '<adress>Amboss Rampe<br/>Zollstrasse 80<br/>8005 Zürich',
    position: [47.38132, 8.53146],
    icon: marker,
},{
    name: 'Kasheme',
    popup: '<adress>Kasheme<br/>Neugasse 56<br/>8005 Zürich',
    position: [47.38281, 8.52955],
    icon: markerKasheme,
},{
    name: 'Plaza',
    popup: '<adress>Plaza<br/>Badenerstrasse 109<br/>8004 Zürich',
    position: [47.37406, 8.52459],
    icon: markerPlaza,
},{
    name: 'Volkshaus',
    popup: '<adress>Volkshaus<br/>Stauffacherstrasse 60<br/>8004 Zürich',
    position: [47.37553, 8.52723],
    icon: markerVolkshaus,
},{
    name: 'Hallenstadion',
    popup: '<adress>Hallenstadion Zürich<br/>Wallisellenstrasse 45<br/>8050 Zürich',
    position: [47.41143, 8.55159],
    icon: markerHallenstadion,
},{
    name: 'Langstrasse',
    popup: '<adress>25hours Hotel Langstrasse<br/>Langstrasse 150<br/>Aussersihl<br/>8004 Zürich',
    position: [47.38042, 8.52870],
    icon: markerHotel25,
},{
    name: 'Zürich West',
    popup: '<adress>25hours Hotel Zürich West<br/>Pfingstweidstrasse 102<br/>8005 Zürich',
    position: [47.39098, 8.50991],
    icon: markerHotel25West,
},{
    name: 'Sportanlage Hardhof',
    popup: '<adress>Sportanlage Hardhof<br/>Hardhof 19<br/>8064 Zürich',
    position: [47.39658, 8.49744],
    icon: marker,
}];

const styles = theme => ({
    '@global':{
        '.leaflet-container': {
            flex: '1 1 auto',
            width: '100%',
            margin: '0 auto',
        }
    },
});

class MyMap extends React.Component {
    constructor (props) {
        super (props);
        this.state = { components: undefined }
    }

    shouldComponentUpdate(){
        return !this.state.components;
    }

    componentDidMount () {
        let L = require('leaflet');


        // stupid hack so that leaflet's images work after going through webpack
        delete L.Icon.Default.prototype._getIconUrl;

        L.Icon.Default.mergeOptions({
            iconUrl: marker,
        });

        L.Icon.Default.mergeOptions({
            iconUrl: marker,
        });

        let {
            Map: LeafletMap,
            Marker,
            TileLayer,
            Popup,
        } = require('react-leaflet');

        this.setState({
            components: {
                LeafletMap,
                Marker,
                TileLayer,
                Popup,
                Icon: L.Icon
            }
        })
    }

    render() {
        if (!this.state.components) {
            //statische Karte
            return (<div></div>);
        } else {
            const {
                LeafletMap,
                Marker,
                TileLayer,
                Popup,
                Icon
            } = this.state.components;

            const name = this.props.location? this.props.location.toLowerCase(): '';

            const current = locations.find(location => {
                return name.indexOf(location.name.toLowerCase()) >= 0
            });

            const markers = locations.map(d => {
                let icon = new Icon({
                    iconUrl: d.icon,
                    iconAnchor: [0, 64],
                    iconSize: [64, 64],
                });
                return (
                    <Marker position={d.position} key={d.position} icon={icon}>
                        <Popup>
                            <div dangerouslySetInnerHTML={{ __html: d.popup}}></div>
                        </Popup>
                    </Marker>
                )
            });
            const position = current? current.position: [47.38281, 8.52955];
            const zoom = current? 17: 13;
            return (
                <LeafletMap center={position} zoom={zoom} minZoom={12} maxZoom={18}>
                    <TileLayer
                        attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        url="https://map.slam2018.ch/tiles/{z}/{x}/{y}.png"
                    >
                    </TileLayer>
                    {markers}
                </LeafletMap>
            );
        }
    };
}
export default injectSheet(styles)(MyMap);
import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss';
import SocialMediaIcon, {MediaType} from '../SocialMediaIcon';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBus} from '@fortawesome/free-solid-svg-icons/faBus';

let headerHeight = '5em';
const styles = theme => ({
    root: {
        flex: '1 1 auto',
        display: 'flex',
        marginTop: '2rem',
        border: '2px solid ' + theme.palette.primary.dark,
        borderRadius: 4,
        marginLeft: 0,
        marginRight: 0,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        flex: '0 0 auto',
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },

    header:{
        display: 'flex',
        borderBottom: '2px solid' + theme.palette.primary.dark,
        height: headerHeight,
        alignItems: 'center',
        '& h3':{
            boxSizing: 'inherit',
            margin: 0,
            marginRight: 30,
            padding: '1rem 24px',
        },

    },
    control: {
        flex: '1 1 auto',
    },
    content: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 12,
        paddingLeft: 24,
        paddingRight: 24,
        position: 'relative',
    },
    image: {
        flex: '0 0 auto',
        maxHeight: '40vh',
        '& img, & picture': {
            maxHeight: '40vh',
        }
    },
    address: {
        margin: '1rem 0',
        flex: '1 1 auto',
    },
    socialMedia:{
        flex: '0 0 auto',
        minHeight: '10%',
        margin: 0,
        listStyle: 'none',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },

    sideBox: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        borderLeft: '2px solid' + theme.palette.primary.dark,
        [theme.breakpoints.down('sm')]: {
            borderLeftWidth: 0,
            borderTop: '2px solid' + theme.palette.primary.dark,
        },
        overflow: 'hidden',
    },
    map: {
        position: 'absolute',
        top:0, right: 0, bottom: 0, left: 0,
        display: 'flex',
    },
    mapWrapper: {
        display: 'flex',
        minHeight: '60vh',
        position: 'relative',
        borderTop: '2px solid '+theme.palette.primary.dark,
    },
    sidebar:{
        zIndex: 1100,
        width: '100%',
        maxHeight: '60vh',
        background: 'white',

        transition: 'transform .75s ease-in',
        transform: 'translateX(200%)',
        '&.open':{
            display: 'block',
            transform: 'translateX(0)',

//            maxHeight: '100%',
//            overflowY: 'auto',
        }
    },

    vbzbox: {
        display: 'flex',
        flex: '0 0 auto',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: "calc("+headerHeight+" - 2px)",
    },
    message: {
    },
    button: {
        '&.touchable': {
            margin: '0 1em',
            zIndex: 900,
            left: '1em',
            bottom: '1em',
            border: '2px solid ' + theme.palette.background,
            color: theme.palette.background,
            background: theme.palette.backgroundSecondary,
            borderRadius: 4,
            display: 'flex',
            width: '2em',
            height: '2em',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            borderColor: theme.palette.backgroundSecondary,
            '&:hover': {
                color: theme.palette.backgroundSecondary,
                background: theme.palette.background,
            }
        }
    },
});

let locations = [{
    name: 'Schauspielhaus',
    poi: 'A=4@O=Zürich, Schauspielhaus Zürich (Theater)@X=8549083@Y=47370222@U=104@L=970001377@B=1@p=1487066158@',
},{
    name: 'X-TRA',
    poi: 'A=4@O=Zürich, X-Tra@X=8532390@Y=47384101@U=104@L=970013308@B=1@p=1487066158@'
},{
    name: 'Kosmos',
    poi: 'A=2@O=Zürich, Lagerstrasse 98-121@X=8528821@Y=47379804@U=103@L=990214619@B=1@p=1488793769@'
},{
    name: 'Amboss',
    poi: 'A=4@O=Zürich, Amboss Rampe@X=8531626@Y=47381171@U=104@L=970013527@B=1@p=1487066158@'
},{
    name: 'Kasheme',
    poi: 'A=2@O=Zürich, Neugasse 50-63@X=8529370@Y=47382762@U=103@L=990214870@B=1@p=1488793769@'
},{
    name: 'Plaza',
    poi: 'A=2@O=Zürich, Badenerstrasse 109@X=8524543@Y=47374276@U=103@L=990213492@B=1@p=1488793769@'
},{
    name: 'Volkshaus',
    poi: 'A=4@O=Zürich, Volkshaus (Theater)@X=8527194@Y=47375274@U=104@L=970010073@B=1@p=1487066158@'
},{
    name: 'Hallenstadion',
    poi: 'A=1@O=Zürich, Messe/Hallenstadion@X=8550081@Y=47410952@U=85@L=008591273@B=1@p=1539678713@'
},{
    name: 'Langstrasse',
    poi: 'A=2@O=Zürich, Langstrasse 149@X=8528039@Y=47380227@U=103@L=990214634@B=1@p=1488793769@'
},{
    name: 'Zürich West',
    poi: 'A=2@O=Zürich, Pfingstweidstrasse 102@X=8509090@Y=47390546@U=103@L=990214981@B=1@p=1488793769@'
},{
    name: 'Sportanlage Hardhof',
    poi: 'A=4@O=Zürich, Sportanlage Hardhof@X=8497152@Y=47396488@U=104@L=970003379@B=1@p=1487066158@'
}];

class FahrplanAbfrage extends React.Component{
    state = {
        iFrame: null,
    };

    constructor(props){
        super(props);

        this.iFrameRef = React.createRef();
        this.onLoad = this.onLoad.bind(this);
    }

    onLoad(){
        let current = this.iFrameRef.current;
        window.iFrameResize(current);
    }

    render(){
        const {date, time, to, active} = this.props;

        let iFrame = this.state.iFrame;
        if(!iFrame){
            let params = '';
            params += '&date='+date;
            params += '&time='+time;
            params += '&REQ0HafasSearchForw=0';
            params += '&ZID='+to;
            iFrame = (
                <iframe
                    title="Fahrplan"
                    src={"https://online.fahrplan.zvv.ch/bin/query.exe/dn?L=vs_iframe&tpwidget"+params}
                    width="100%"
                    style={{
                        'border': 'none',
                        maxWidth: 760,
                        height: '100%',
                        '-webkit-overflow-scrolling': 'touch',
                    }}
                    ref={this.iFrameRef}
                />
            );
            this.setState({iFrame});
        }
        return (
            <>
                {this.state.iFrame}
            </>
        );
    }
};

class LocationBox extends React.Component {
    state = {
        open: false
    };

    toogleSidebar(){
        this.setState({open: !this.state.open});
    }

    render() {
        let {date, time, image, title, address, website, facebook, instagram, map, classes} = this.props;
        let socialMedia = [];
        if(website){
            socialMedia.push(<li><SocialMediaIcon href={website} type={MediaType.WEBSITE}/></li>);
        }
        if(facebook){
            socialMedia.push(<li><SocialMediaIcon href={facebook} type={MediaType.FACEBOOK}/></li>);
        }
        if(instagram){
            socialMedia.push(<li><SocialMediaIcon href={instagram} type={MediaType.INSTAGRAM}/></li>);
        }
        if(socialMedia.length>0){
            socialMedia = (
                <ul className={classes.socialMedia}>{socialMedia}</ul>
            );
        } else {
            socialMedia = null;
        }

        const name = title.toLowerCase();
        let toAddress = locations.find(e => name.indexOf(e.name.toLowerCase()) >= 0);
        if(toAddress){
            toAddress = toAddress.poi;
        }

        return (
            <section className={classes.root}>
                <div className={classes.details}>
                    <header className={classes.header}>
                        <h3>{title}</h3>
                        <div className={classes.control}></div>
                    </header>
                    <div className={classes.content}>
                        <div className={classes.image}>
                            {image}
                        </div>
                        <address className={classes.address}>{address}</address>
                        {socialMedia}
                    </div>
                </div>
                <div className={classes.sideBox}>
                    <div className={classes.vbzbox}>
                        <a className={classes.button + " touchable"} onClick={() => this.toogleSidebar()}>
                            <FontAwesomeIcon icon={faBus}/>
                        </a>
                        <span className={classes.message}>
                                Die Anreise mit dem ÖV wird empfohlen. Plane deine Anreise jetzt mit dem Online-Fahrplan!
                            </span>
                    </div>
                    <div className={classes.mapWrapper}>
                        <div className={classes.sidebar + (this.state.open? ' open': '')}>
                            <FahrplanAbfrage date={date.format('D.MM.YY')} time={time} to={toAddress} active={this.state.open}/>
                        </div>
                        <div className={classes.map}>
                            {map}
                        </div>
                    </div>
                </div>
            </section>
        );
    };
}

LocationBox.propTypes = {
    image: PropTypes.node,
    title: PropTypes.string,
    address: PropTypes.string,
    website: PropTypes.string,
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    map: PropTypes.node,
};

export default injectSheet(styles)(LocationBox);
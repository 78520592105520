import {connect} from "react-redux";
import ProgramContest from "../components/program/ProgramContest";

const slammersByCompetition = (state, competition) => {
    if(state.competitions[competition]){
        let hasSlammer = false;
        let slammers = state.competitions[competition].participants.map(id => {
            let participant = state.participantsById[id];
            if(participant && participant.name){
                hasSlammer = true;
                return {
                    name: participant.name,
                    veranstaltung: participant.slam,
                    scores: participant.scores,
                };
            }
        });
        if(hasSlammer)
            return slammers;
    }
    return [];
};

const mapStateToProps = (state, ownProps) => ({
    slammers: slammersByCompetition(state, ownProps.competition)
});

export default connect(
    mapStateToProps,
)(ProgramContest);

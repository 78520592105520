import React from 'react';
import injectSheet from 'react-jss';
import ProgramContestResultContent from "./ProgramContestResultContent";
import Link from "../Link";

const styles = theme => {
    let color = theme.palette.primary.dark;
    //color = theme.palette.secondary.dark;
    //color = theme.palette.backgroundSecondary;
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '2rem',
        },
        slammer: {
            display: 'flex',
            border: '2px solid '+ color,
            color: color,
            marginBottom: '.5rem',
            fontSize: '1.25rem',
            fontWeight: 400,
            flexWrap: 'wrap',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
            },
        },
        name: {
            width: '40%',
            minWidth: '40%',
            color: theme.palette.background,
            background: color,
            padding: '.5rem',
            [theme.breakpoints.down('xs')]: {
                width: 'auto',
            },
        },
        master: {
            flex: '1 1 auto',
            padding: '.5rem',
        },
    };
};

const ProgramContest = ({slammers, classes}) => {
    return (
        <section className={classes.root}>
            {
                slammers.map((slammer) => {
                    if(!slammer)
                        slammer = {};
                    const {name, veranstaltung, scores} = slammer;
                    return (
                        <section className={classes.slammer}>
                            <div className={classes.name}>{name}</div>
                            <div className={classes.master}>{veranstaltung}</div>
                        </section>
                    )
                })
            }
            <Link style={{margin: '24px 0', width: '6.5em'}} to={'/programm/resultate'} className="button">Resultate</Link>
        </section>
    );
};


export default injectSheet(styles)(ProgramContest);
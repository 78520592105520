import React from "react";
import injectSheet from 'react-jss';
import 'dayjs/locale/de';
import dayjs from "dayjs";

import Link from 'gatsby-link';

const styles = theme => {
    let width = '20em';
    let widthDay = '3.75em';
    let fontSize = 1.8;
    let breakPoint = theme.breakpoints.down('lg');
    let time = .25;
    return {
        root: {
            flex: '0 0 auto',
            position: 'relative',
            listStyle: 'none',
            margin: '4em 0 0 2em',
            width,
            color: theme.palette.backgroundSecondary,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            [breakPoint]: {
                width: '100%',
            },
            '&.hover $dayWrapper': {
                '& $day': {
                    cursor: 'default',
                },
                '& $dayContent': {
                    display: 'none',
                },
                '&:hover $dayContent': {
                    display: 'flex',
                    zIndex: 10,
                    background: theme.palette.background,
                },
                '&.current $dayContent':{
                    display: 'flex',
                    zIndex: 1,
                },
            },
            '&.selectable $dayWrapper': {
                '& $dayContent': {
                    display: 'none',
                    [breakPoint]:{
                        display: 'flex',
                        maxHeight: 0,
                        overflow: 'hidden',
                        transition: 'max-height '+time+'s ease-in '+0+'s',
                    },
                },
                '&.selected $dayContent': {
                    display: 'flex',
                    [breakPoint]:{
                        maxHeight: '300px',
                        transitionDelay: time/2+'s',
                        zIndex: 10,
                        background: theme.palette.background,
                    },
                },
            },
        },
        dayWrapper: {
            margin: 0,
            display: 'flex',
            alignItems: 'stretch',
            '&.selected.current  $day, &.current  $day': {
                color: theme.palette.text.primary,
                '&:hover':{
                    cursor: 'pointer',
                    color: theme.palette.primary.light,
                },
            },
            '&.selected $day': {
                color: theme.palette.primary.light,
            },
            [breakPoint]: {
                flexWrap: 'wrap',
            }
        },
        day: {
            fontSize: fontSize+'em',
            fontWeight: 300,
            width: widthDay,
            textTransform: 'uppercase',
            lineHeight: '1.3em',
            '&:hover':{
                cursor: 'pointer',
                color: theme.palette.primary.light,
            },
        },
        dayContent: {
            borderLeft: '2px solid '+theme.palette.backgroundSecondary,
            listStyle: 'none',
            margin: 0,
            position: 'absolute',
            top: 0, bottom: 0, right: 0,
            width: 'calc('+width+' - '+(fontSize)+' * '+widthDay+')',
            flexDirection: 'column',
            fontWeight: 800,
            [breakPoint]: {
                position: 'relative',
                width: 'auto',
            }
        },
        timeGroupWrapper: {
            display: 'flex',
            margin: 0,
            marginLeft: '1.5em',
            fontSize: '.9em',
            [breakPoint]: {
//                flexDirection: 'column',
                flexWrap: 'wrap',
            },
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
            },
        },
        timeGroup: {
            width: '3em',
            '&.current':{
                color: theme.palette.text.primary,
            },
        },
        timeGroupContent:{
            listStyle: 'none',
            margin: 0,
            [breakPoint]: {
                display: 'flex',
//                flexDirection: 'column',
                flex: '1 1 auto',
                width: '100%',
            },
            [theme.breakpoints.down('xs')]: {
                flexWrap: 'wrap',
            }
        },
        time: {
            margin: 0,
            marginLeft: '1.5em',
            '& a:hover':{
                color: theme.palette.secondary.light,
            },
            '&.current a':{
                color: theme.palette.text.primary,
                '&:hover':{
                    color: theme.palette.secondary.light,
                },
            },
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
                marginRight: '1.5em',
                '&:first-child':{
//                    margin: 0,
                }
            }
        },
    }
};


class ProgramMenu extends React.Component{
    state = {
        loaded: false,
    };

    componentDidMount() {
        this.selectDay(this.props.currentDay);
    }

    selectDay(selectedDay){
        this.setState({selectedDay});
    }

    groupEvents(edges) {
        let eventsByTime = [];
        let time;
        let events;
        edges.forEach(({event})=>{
            if(event.custom_fields.wpcf_tueroeffnung[0]!==time){
                if(events) eventsByTime.push({time,events});
                time = event.custom_fields.wpcf_tueroeffnung[0];
                events = []
            }
            events.push(event);
        });
        if(events && time) eventsByTime.push({time,events});
        return eventsByTime;
    };

    renderDayContent(edges, isCurrentDay){
        const {currentTime, currentEvent, classes} = this.props;
        return (
            <ul className={classes.dayContent}>
                {
                    this.groupEvents(edges).map(({time, events}) => (
                        <li className={classes.timeGroupWrapper}>
                            <div
                                className={classes.timeGroup + (isCurrentDay && time === currentTime ? ' current' : '')}>{time.replace('.', 'h')}</div>
                            <ul className={classes.timeGroupContent}>
                                {
                                    events.reverse().map(event => (
                                        <li className={classes.time + (event.id === currentEvent ? ' current' : '')}>
                                            <Link to={'/' + event.slug + '#navigation'}>{event.title}</Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </li>
                    ))
                }
            </ul>
        )
    }

    render() {
        const {events, currentDay, classes} = this.props;
        let additionalClasses =  this.state.selectedDay? ' selectable': ' hover';
        return (
            <ul className={classes.root + additionalClasses}>
                {
                    events.group.map(({fieldValue, edges}) => {
                        let date = dayjs(fieldValue * 1000).locale('de');

                        let isSelected = this.state.selectedDay===fieldValue;
                        let isCurrentDay = currentDay === fieldValue;
                        let additionalClasses = isCurrentDay? ' current' : '';
                        if(isSelected)
                            additionalClasses += ' selected';

                        return (
                            <li className={classes.dayWrapper + additionalClasses}>
                                <div className={classes.day} onClick={() => this.selectDay(fieldValue)}>{date.format('dd DD.MM.')}</div>
                                {this.renderDayContent(edges, isCurrentDay)}
                            </li>
                        );
                    })
                }
            </ul>
        );
    }
}

export default injectSheet(styles)(ProgramMenu);